
$('.js-open-modal').click((e) => {
    e.preventDefault();
    const w = $(window).outerWidth();
    const popup = $(e.currentTarget).attr('data-popup');
    if (w < 443) {
        $('.popup').removeClass('is-visible');
        $(`.${popup}`).addClass('is-visible');
        return;
    }
    $(`.${popup}`).fadeIn(600);
});

$('.js-close-modal').click((e) => {
    e.preventDefault();
    const w = $(window).outerWidth();
    const popup = e.target.closest('.popup');
    if (w < 443) {
        $(popup).removeClass('is-visible');
        return;
    }
    $(popup).fadeOut(600);
});
document.addEventListener('touchmove', (event) => {
    if ($('.popup').hasClass('is-visible')) {
        event.preventDefault();
    }
}, { passive: false });
$('.js-hide-greet').click((e) => {
    e.preventDefault();
    const popup = e.target.closest('.popup');
    $(popup).fadeOut(600);
});

$('.nav-side__btn').click(function (e) {
    e.preventDefault();
    $('.nav-side__btn').removeClass('is-active');
    $(this).addClass('is-active');
    if ($(this).hasClass('tile')) {
        $('.time-line').hide();
        $('.cards').fadeIn(400);
    }
    if ($(this).hasClass('list')) {
        $('.cards').hide();
        $('.time-line').fadeIn(400);
    }
    $('.time-line').toggleClass('is-blocked');
    $('.cards').toggleClass('is-blocked');
});

$(window).scroll(function () {
    $('.nav-side').removeClass('is-active');
    if ($(window).scrollTop() > $(window).outerHeight()) {
        clearTimeout($.data(this, 'scrollTimer'));
        $.data(this, 'scrollTimer', setTimeout(() => {
            $('.nav-side').removeClass('hide-icon');
            $('.nav-side').addClass('is-active');
        }, 250));
    } else {
        $('.nav-side').addClass('hide-icon');
    }
});


$('.nav-side__icon').click(() => {
    // $('.nav-side').removeClass('is-active');
    $('.nav-side').toggleClass('is-blocked');
});

// $(window).scroll(() => {
//     console.log('jd');
// });
// $(window).scroll(() => {
//     const h = $(window).outerHeight();
//     //     // if ($(window).scrollTop() > h / 0.5) {
//     //     //     console.log('done');
//     //     //     $('.trigger').hide();
//     //     //     $('.content').removeClass('is-fixed');
//     //     // }
//     //     // console.log($(window).scrollTop());
//     //     const sc = $(window).scrollTop() / 3;
//     const sco = $(window).scrollTop();

//     if (sco < h / 3 && sco > '30') {
//         $('.content').css({
//             transform: `translate3d(0,${sco}px,0)`,
//             '-webkit-transform': `translate3d(0,${sco}px,0)`,
//             '-ms-transform': `translate3d(0,${sco}px,0)`,
//             '-o-transform': `translate3d(0,${sco}px,0)`,

//         });
//         $('.greet').fadeOut(600);
//     } else if (sco >= h / 3) {
//         $('.greet').fadeOut(600);
//     } else {
//         $('.greet').fadeIn(600);
//         $('.content').css({
//             transform: `translate3d(0,${sco}px,0)`,
//             '-webkit-transform': `translate3d(0,${sco}px,0)`,
//             '-ms-transform': `translate3d(0,${sco}px,0)`,
//             '-o-transform': `translate3d(0,${sco}px,0)`,

//         });
//     }
// });
const animateGreetScreen = () => {
    // const h = $(window).outerHeight();
    const tl = new TimelineLite();
    const tl2 = new TimelineLite();
    const controller = new ScrollMagic.Controller();

    // if (!($('.js-hide-greet').hasClass('is-animated'))) {
    //     alert(';');

    tl
        .to('.greet ', 1.2, {
            x: '0%',
            autoAlpha: 0,
            ease: Power0.easeNone,
        })
        // .to('.greet__bg ', 1.2, {
        //     backgroundColor: '#ffffff',
        //     ease: Power0.easeNone,
        // }, '-=1.6')
        // .to('.greet .btn', 1, {
        //     autoAlpha: 0,
        //     ease: Power0.easeNone,
        // }, '-=1.4')
        // .to('.greet .title', 1.2, {
        //     y: '20',
        //     autoAlpha: 0,
        //     ease: Power0.easeNone,
        // }, '-=1.2')
        // .to('.greet .subtitle', 1.2, {
        //     y: '20',
        //     autoAlpha: 0,
        //     ease: Power0.easeNone,
        // }, '-=1.2')
        // .to('.greet .subtitle', 0.9, {
        //     y: '20',
        //     autoAlpha: 0,
        //     ease: Power0.easeNone,
        // }, '-=1.3')
        .to('.hero .subtitle', 1.5, {
            y: '0',
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, '-=1')
        .to('.hero .title', 1.5, {
            y: '0',
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, '-=1.7');
    // .to('.content', 0.1, { className: '-=is-fixed' }, '0');
    // .to('.trigger', 0.1, { className: '-=is-show' }, '-=0.1');
    // } else {
    //     alert('k');
    // }

    $('.js-hide-greet').click((e) => {
        e.preventDefault();
        // $('.trigger').hide();
        // $('.content').removeClass('is-fixed');

        tl2
            .to('.greet ', 1.2, {
                x: '0%',
                autoAlpha: 0,
                ease: Power0.easeNone,
            })
            // .to('.greet__bg ', 1.2, {
            //     backgroundColor: '#ffffff',
            //     ease: Power0.easeNone,
            // }, '-=1.6')
            // .to('.greet .btn', 1, {
            //     autoAlpha: 0,
            //     ease: Power0.easeNone,
            // }, '-=1.4')
            // .to('.greet .title', 1.2, {
            //     y: '20',
            //     autoAlpha: 0,
            //     ease: Power0.easeNone,
            // }, '-=1.2')
            // .to('.greet .subtitle', 1.2, {
            //     y: '20',
            //     autoAlpha: 0,
            //     ease: Power0.easeNone,
            // }, '-=1.2')
            // .to('.greet .subtitle', 0.9, {
            //     y: '20',
            //     autoAlpha: 0,
            //     ease: Power0.easeNone,
            // }, '-=1.3')
            .to('.hero .subtitle', 1.5, {
                y: '0',
                autoAlpha: 1,
                ease: Power0.easeNone,
            }, '-=1')
            .to('.hero .title', 1.5, {
                y: '0',
                autoAlpha: 1,
                ease: Power0.easeNone,
            }, '-=1.7');
        $('.trigger').remove();
    });

    // const tt = new ScrollMagic.Scene({
    new ScrollMagic.Scene({
        triggerElement: '.trigger',
        // triggerElement: '.greet-scroll',
        // triggerElement: '.greet',
        triggerHook: 0,
        offset: '100',
        duration: '100%',
    })
        .setTween(tl)
        .setPin('.hero')
        // .addIndicators()
        .addTo(controller);
    // tt.on('end', (event) => {
    //     console.log('Hit end point of scene.');
    // });
};
animateGreetScreen();

const animateCircle = () => {
    const controller = new ScrollMagic.Controller();
    $('.time-line__circle').each(function () {
        const tlScroll = new TimelineLite();
        const circle = $(this).find('.circle-mask');
        const elHeight = $(this).outerHeight();
        tlScroll
            .to(circle, 0.3, {
                height: 0,
                ease: Power0.easeNone,
            });
        const scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.5,
            duration: elHeight,
        })
            .setTween(tlScroll)
            // .addIndicators()
            .addTo(controller);
    });
};
animateCircle();

const animateSection = () => {
    const controller = new ScrollMagic.Controller();
    $('.time-line .additional').each(function () {
        const tlScroll = new TimelineLite();
        const elHeight = $(this).outerHeight();
        tlScroll
            .fromTo(this, 1.4, {
                x: 100,
                ease: Elastic.easeOut,
                opacity: 0,
            }, {
                x: 0,
                ease: Elastic.easeOut,
                opacity: 1,
            });
        const scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.5,
            duration: 0,
        })
            .setTween(tlScroll)
            // .addIndicators()
            .addTo(controller);
    });
};
animateSection();

const animateCard = () => {
    const controller = new ScrollMagic.Controller();
    $('.card').each(function () {
        const tlScroll = new TimelineLite();
        tlScroll
            .fromTo(this, 0.5, {
                y: 50,
                ease: Power0.easeNone,
                opacity: 0,
            }, {
                y: 0,
                ease: Power0.easeNone,
                opacity: 1,
            });
        const scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.9,
            duration: '0%',
        })
            .setTween(tlScroll)
            .addTo(controller);
    });
};
animateCard();

const windowHeight = $(window).outerHeight() / 2;
$('.nav-side__list a').bind('click', function (e) {
    e.preventDefault();

    const target = $(this).attr('data-scroll');

    if ($('.cards').hasClass('is-blocked')) {
        $('html, body').stop().animate({
            scrollTop: $(`.is-blocked .${target}`).offset().top,
        }, 1600);
    } else {
        $('html, body').stop().animate({
            scrollTop: $(`.is-blocked .${target}`).offset().top - windowHeight,
        }, 1600);
    }

    return false;
});

$(window).scroll(() => {
    const scrollDistance = $(window).scrollTop();

    if ($('.cards').hasClass('is-blocked')) {
        $('.cards__block').each(function (i) {
            if ($(this).offset().top - windowHeight + -1 <= (+scrollDistance)) {
                $('.nav-side__list a').removeClass('is-active');
                const id = $(this).attr('data-year');
                $(`#${id}`).addClass('is-active');
            }
        });
    } else {
        $('.time-line__content.disable .circle').each(function (i) {
            if ($(this).offset().top - windowHeight + -1 <= (+scrollDistance)) {
                $('.nav-side__list a').removeClass('is-active');
                const id = $(this).attr('data-year');
                $(`#${id}`).addClass('is-active');
            }
        });
    }
}).scroll();
